<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form @submit="handleSubmit" :form="form">
      <a-form-item v-for="item in select_config" :key="item.key" :label="item.name" :labelCol="labelCol"
        :wrapperCol="wrapperCol">
        <a-select v-decorator="[
          item.key,
          {
            initialValue: item.value
          }
        ]">
          <a-select-option v-for="template in getFilterEmail(item.key)" :value="template.id" :key="template.id">{{
            template.name
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { emailSelectSet, saveEmailSelectSet } from '@/api/email'

export default {
  name: 'EmailSettings',
  data() {
    return {
      description: '邮件发送设置页面',
      value: 1,
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      queryData: {},
      select_config: {},
      template_list: {},
      // form
      form: this.$form.createForm(this),
      submitLoading: false
    }
  },
  created() {
    this.queryData = this.$route.query
    new Promise((resolve, reject) => {
      emailSelectSet().then(response => {
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
    }).then(res => {
      this.template_list = res.data.template_list
      this.select_config = res.data.select_config
      console.log(res)
    }).catch(error => {
      this.$notification.error({
        message: '错误',
        description: error.message
      })
    })
  },
  computed: {
    getFilterEmail() {
      return (key) => {
        return this.template_list.filter((item) => item.type === key)
      }
    }
  },
  //   mounted () {
  //     this.form.setFieldsValue({ 'email_template_verifica': '1' })
  //   },
  methods: {
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          new Promise((resolve, reject) => {
            this.submitLoading = true
            values.id = this.queryData.id
            saveEmailSelectSet(values).then(response => {
              if (response.status === 'success') {
                resolve(response)
              } else {
                reject(response)
              }
            })
          }).then(res => {
            this.$notification.success({
              message: res.status,
              description: res.message
            })
          }).catch(error => {
            this.$notification.error({
              message: '错误',
              description: error.message
            })
          }).finally(() => {
            this.submitLoading = false
          })
        }
      })
    }
  }
}
</script>
