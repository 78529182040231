import api from './index'
import { axios } from '@/utils/request'

// 获取可替换变量
export function EmailReplaceVars() {
    return axios({
        url: api.emailReplaceVars,
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取邮件类型
export function emailType() {
    return axios({
        url: api.EmailType,
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 新增时，邮件模板页面数据
export function getAddTempPage() {
    return axios({
        url: api.GetAddTempPage,
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取所有邮件模板
export function emailTemplateList(parameter) {
    return axios({
        url: api.EmailTemplateList,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取模板详情
export function emailTemplateInfo(parameter) {
    return axios({
        url: api.EmailTemplateInfo,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 新增邮件模板
export function addEmailTemplate(parameter) {
    return axios({
        url: api.AddEmailTemplate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 修改邮件模板
export function updateEmailTemplate(parameter) {
    return axios({
        url: api.UpdateEmailTemplate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除邮件模板
export function delEmailTemplate(parameter) {
    return axios({
        url: api.DelEmailTemplate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取发送配置
export function emailSelectSet() {
    return axios({
        url: api.EmailSelectSet,
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 保存发送配置
export function saveEmailSelectSet(parameter) {
    return axios({
        url: api.saveEmailSelectSet,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取邮件日志
export function emailLog(parameter) {
    return axios({
        url: api.EmailLog,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
